.search-group {
    display: flex;
    input {
        padding-right: 2.5rem;
        flex-shrink: 0;
    }
    span {
        position: relative;
        right: 2.25rem;
        top: 0.25rem;
        .fa {
            color: $pagination-link !important;
            font-size: 1.5rem;
        }
    }
}
.report-range {
    display: inline-block;
    input:read-only {
        background: $white;
    }
}

.drp-buttons {
    .btn {
        border-radius: 1rem;
        min-width: 6rem;
        &.btn-primary {
            background: $secondary;
            border-color: $secondary;
        }
    }
}

input.password-revealable {
    width: calc(100% - 48px);
}

.btn-password-reveal {
    @extend .btn, .btn-link, .px-2;
    width: 32px;
    position: absolute;
    right: 16px;
    top: 23px;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

#passwordLights {
    list-style: none;

    li {
        .fa {
            color: #c3c7d8;
        }

        &.dont-pass {
            .fa {
                color: $danger;
            }
        }

        &.passed {
            .fa {
                color: $success;
            }
        }

    }
}

.form-control {
    &.is-invalid {
        &.password-eye {
            background-image: none;
        }
    }
    &.text-break {
        height: auto;
        min-height: calc(1.6em + 0.75rem + 2px);
    }
}
