// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

@import "~daterangepicker/daterangepicker.css";

// Custom
@import "global-styles";
@import "menus";
@import "buttons";
@import "pagination";
@import "forms";
@import "table";
@import "daterangepicker";
