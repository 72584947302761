.main-wrapper {
    height: 100vh;
}

.section-base > .fixed-bottom {
        right: unset;
        left: unset;
}

.input-group-text {
    border-radius: 1.5rem;
}

.fa {
    &.orange {
        color: $orange;
    }
    &.blue {
        color: $blue;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.limited-username {
    max-width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &::after {
        display: inline-block;
        position: absolute;
        right: 5px;
        top: 50%;
        margin-top: -0.1em;
    }
}

.navbar-expand-md {
    .navbar-nav {
        .nav-link {
            &.limited-username {
                padding-right: 1.5em;
            }
        }
    }
}
