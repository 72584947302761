.report-range {
    width: 100%;
}

.daterangepicker {
    .drp-buttons {
        .btn:first-of-type {
            margin: 0 0 0.5rem 0.5rem;
        }
    }
}

@media (min-width: 576px) {
    .daterangepicker {
        .drp-buttons {
            .btn:first-of-type {
                margin: 0 0 0 0.5rem;
            }
        }
    }
}
