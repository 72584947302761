.table {
    th {
        &.days-counter-column {
            max-width: 7.5rem;
            width: 7.5rem;
        }
        &.total-sims-column {
            max-width: 7rem;
            width: 7rem;
        }
        &.actions-column {
            max-width: 5.5rem;
            width: 5.5rem;
        }
    }
    tr {
        &.deleted {
            text-decoration: line-through;
        }
    }
    .category {
        color: $white;
        span {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 50%;
            display: block;
            padding: 0.2rem 0;
            text-align: center;
            margin: 0 auto;
        }
        &.green {
            span {
                background: $dark-green;
            }
        }
        &.yellow {
            span {
                background: $dark-yellow;
                color: $black;
            }
        }
        &.green {
            span {
                background: $dark-green;
            }
        }
        &.red {
            span {
                background: $dark-red;
            }
        }
        &.text {
            span {
                width: auto;
                height: auto;
                border-radius: 0;
                color: $black;
                font-weight: bold;
            }
        }
    }
    .actions {
        font-size: 1.3rem;
    }
}
