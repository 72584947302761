.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;

    > a {
        cursor: pointer;
    }

    .item {
        width: 30px;
        height: 30px;
        padding: 4px 0;
        color: $pagination-link;
    }

    .arrow {
        > i {
            &::before {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), $gray;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .dots {

    }
    .current {
        border-radius: 50%;
        color: $white;
        background-color: $primary;
    }
}
